/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { Fragment } from "react"
import { MdShare } from "react-icons/md"
import { FaPinterestP, FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa"
import { visuallyHiddenCss } from "./shared/styles/a11y"
import { IconAnchorButton } from "gatsby-interface"

const objectToParams = object =>
  `?` +
  Object.keys(object)
    .filter(key => !!object[key])
    .map(key => `${key}=${encodeURIComponent(object[key])}`)
    .join(`&`)

const ShareMenuItem = ({ href, icon, children }) => (
  <IconAnchorButton
    icon={icon}
    size="M"
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      mb: 3,
      display: `flex`,
    }}
    href={href}
    title={children}
  >
    {children}
  </IconAnchorButton>
)

class ShareMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.shareMenu = this.shareMenu.bind(this)
    this.clickOutsideShareMenu = this.clickOutsideShareMenu.bind(this)
    this.setShareBtnRef = this.setShareBtnRef.bind(this)
    this.setShareMenuRef = this.setShareMenuRef.bind(this)
  }

  componentDidMount() {
    document.addEventListener(`mousedown`, this.clickOutsideShareMenu)
  }

  componentWillUnmount() {
    document.removeEventListener(`mousedown`, this.clickOutsideShareMenu)
  }

  setShareBtnRef(node) {
    this.shareBtnref = node
  }

  setShareMenuRef(node) {
    this.shareMenuRef = node
  }

  clickOutsideShareMenu(event) {
    const { open } = this.state
    if (
      open &&
      !this.shareBtnref.contains(event.target) &&
      !this.shareMenuRef.contains(event.target)
    ) {
      this.shareMenu()
    }
  }

  shareMenu() {
    const { open } = this.state
    this.setState({
      open: !open,
    })
  }

  render() {
    const { url, title, image, className } = this.props
    const { open } = this.state
    return (
      <Fragment>
        <button
          onClick={this.shareMenu}
          sx={{
            bg: `purple.60`,
            border: 0,
            borderRadius: 2,
            color: `white`,
            cursor: `pointer`,
            height: 36,
            width: 36,
            paddingTop: `7.5px`,
          }}
          className={className}
          ref={this.setShareBtnRef}
        >
          <span sx={visuallyHiddenCss}>Share</span>
          <MdShare />
        </button>
        {open && (
          <div
            css={{
              position: `absolute`,
              top: 44,
              left: `auto`,
              right: 0,
            }}
            ref={this.setShareMenuRef}
          >
            <ShareMenuItem
              href={`https://pinterest.com/pin/create/button/${objectToParams({
                url: url,
                media: image,
                description: title,
              })}`}
              icon={<FaPinterestP />}
            >
              Share on Pinterest
            </ShareMenuItem>
            <ShareMenuItem
              href={`https://www.linkedin.com/shareArticle${objectToParams({
                mini: `true`,
                url: url,
                title: title,
              })}`}
              icon={<FaLinkedin />}
            >
              Share on LinkedIn
            </ShareMenuItem>
            <ShareMenuItem
              href={`https://www.facebook.com/sharer.php${objectToParams({
                u: url,
                t: title,
              })}`}
              icon={<FaFacebook />}
            >
              Share on Facebook
            </ShareMenuItem>
            <ShareMenuItem
              href={`https://twitter.com/share${objectToParams({
                url: url,
                text: title,
              })}`}
              icon={<FaTwitter />}
              title="Share on Twitter"
            >
              Share on Twitter
            </ShareMenuItem>
          </div>
        )}
      </Fragment>
    )
  }
}

export default ShareMenu
